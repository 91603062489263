import './App.css';
import axios from 'axios';

function App() {

  const webProd = () => {
    axios.post(`https://api.vercel.com/v1/integrations/deploy/prj_FIp0M9WweHg1XvOOQjemE8I5ZF4v/XjQ53Qe6Nh`)
      .then(res => {
        console.log(res);
      })
    console.log('webProd')
  }
  const webStaging = () => {
    axios.post(`https://api.vercel.com/v1/integrations/deploy/prj_FIp0M9WweHg1XvOOQjemE8I5ZF4v/8eKqmJWOkW`)
      .then(res => {
        console.log(res);
      })
    console.log('webStaging')
  }
  const staticProd = () => {
    axios.post(`https://api.vercel.com/v1/integrations/deploy/prj_9wKxsE8kKyHlR8yUIbjc2wyMscvm/uSlHYhMdl0`)
      .then(res => {
        console.log(res);
      })
    console.log('staticProd')
  }
  const staticStaging = () => {
    axios.post(`https://api.vercel.com/v1/integrations/deploy/prj_9wKxsE8kKyHlR8yUIbjc2wyMscvm/Qlf4FZBoEi`)
      .then(res => {
        console.log(res);
      })
    console.log('staticStaging')
  }
  return (
    <>
      <div className="Header">
        Welcome home, chosen one
      </div>
      <div className="content">
        <div className="container">
          <span className="col_header">Deploy TECHSTYLE-WEB</span>
          <div className="button_cont">
            <button className="button prod_button" onClick={webProd}>Production</button>
            <button className="button prod_button stag" onClick={webStaging}>Staging</button>
          </div>
        </div>
        <div className="container">
          <span className="col_header">Deploy TECHSTYLE-STATIC</span>
          <div className="button_cont">
            <button className="button prod_button" onClick={staticProd}>Production</button>
            <button className="button prod_button stag" onClick={staticStaging}>Staging</button>
          </div>
        </div>

      </div>
    </>
  )
}

export default App;
